<template>
  <q-card style="min-width: 600px">
    <q-card-section class="row items-center text-h6">
      <template v-if="vehicleDefect.defectNumber">
        Fordonsskada: {{ vehicleDefect.defectNumber }}
      </template>
      <template v-else> Fordonsskada kunde ej hittas! </template>
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pa-none">
      <q-scroll-area
        style="width: 100%; height: 600px"
        v-if="vehicleDefect.defectNumber"
      >
        <table style="table-layout: fixed">
          <tbody>
            <template v-for="h in vehicleReportHeaders" :key="h.key_ford">
              <tr v-if="vehicleDefect && vehicleDefect[h.key_ford]">
                <td
                  style="
                    background: #f2f2f2;
                    font-weight: bold;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                  "
                  class="q-px-md q-py-xs"
                >
                  {{ h.key_swe }}
                </td>
                <td
                  class="q-px-md"
                  style="border-bottom: 1px solid rgba(0, 0, 0, 0.1)"
                >
                  {{ vehicleDefect[h.key_ford] }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </q-scroll-area>
      <div v-else class="flex items-center justify-center q-pa-xl text-grey-6">
        {{ vehicleDefect }}
      </div>
    </q-card-section>
    <q-separator />
    <q-card-actions align="right">
      <q-btn flat label="Stäng" @click="fordModal.closeModal" type="button" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'
import vehicleReportHeaders from '@/common/vehicleReportHeaders'

export default defineComponent({
  name: 'EventFordModal',

  props: {
    eventId: Number,
  },

  setup() {
    const fordModal = inject<UseModal>('ford-modal')

    const vehicleDefect = fordModal?.state.data

    return { fordModal, vehicleDefect, vehicleReportHeaders }
  },
})
</script>
