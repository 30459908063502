import { instance } from '@/services/avik-api-gateway'
import { WithdrawalDashboardWithdrawal } from '@/types/withdrawal-dashboard-withdrawal'
import { AxiosResponse } from 'axios'

export interface GetWithdrawalParams {
  from: string
  to: string
  time: string
}

export function getWithdrawal(
  params: GetWithdrawalParams
): Promise<AxiosResponse<WithdrawalDashboardWithdrawal[]>> {
  return instance.get(`/withdrawal-dashboard/withdrawal`, {
    params,
  })
}
