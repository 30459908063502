import { Ref, ref, ComputedRef, computed } from 'vue'
import { getCustomerAvailabilityStatus } from '@/api/customer-availability-status/getCustomerAvailabilityStatus'
import { useLoading, Loading } from '@/composable/useLoading'
import { CustomerAvailabilityStatus } from '@/types/customer-availability-status'
import { updateCustomerAvailabilityStatus } from '@/api/customer-availability-status/updateCustomerAvailabilityStatus'

interface UseCustomerAvailabilityStatus {
  data: Ref<CustomerAvailabilityStatus[]>
  fetchAll: () => Promise<CustomerAvailabilityStatus[]>
  update: (
    body: CustomerAvailabilityStatus
  ) => Promise<CustomerAvailabilityStatus>
  loading: ComputedRef<Loading>
}

export function useCustomerAvailabilityStatus(
  initFetchAll?: boolean
): UseCustomerAvailabilityStatus {
  const data = ref<CustomerAvailabilityStatus[]>([])
  const { state } = useLoading()

  async function fetchAll() {
    state.getAll = true
    return new Promise<CustomerAvailabilityStatus[]>((resolve, reject) => {
      getCustomerAvailabilityStatus()
        .then(({ data: customerAvailabilityStatus }) => {
          data.value = customerAvailabilityStatus
          resolve(customerAvailabilityStatus)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function update(body: CustomerAvailabilityStatus) {
    state.update = true
    return new Promise<CustomerAvailabilityStatus>((resolve, reject) => {
      updateCustomerAvailabilityStatus(body)
        .then(({ data: customerAvailabilityStatus }) => {
          data.value = data.value.map((item) => {
            if (item.uuid === body.uuid) {
              return {
                ...item,
                color: body.color,
              }
            }

            return item
          })
          resolve(customerAvailabilityStatus)
        })
        .catch(reject)
        .finally(() => {
          state.update = false
        })
    })
  }

  if (initFetchAll) {
    fetchAll()
  }

  return {
    data,
    fetchAll,
    update,
    loading: computed(() => state),
  }
}
