import { instance } from '@/services/avik-api-gateway'
import { WithdrawalDashboardSummary } from '@/types/withdrawal-dashboard-summary'
import { AxiosResponse } from 'axios'

export interface GetSummaryParams {
  from: string
  to: string
  time: string
}

export function getSummary(
  params: GetSummaryParams
): Promise<AxiosResponse<WithdrawalDashboardSummary[]>> {
  return instance.get(`/withdrawal-dashboard/summary`, {
    params,
  })
}
