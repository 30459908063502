export default [
  {
    key_ford: 'CorrelationID',
    key_swe: 'CorrelationID',
  },
  {
    key_ford: 'ErrorCode',
    key_swe: 'Errorkod',
  },
  {
    key_ford: 'ErrorMessage',
    key_swe: 'Felmeddelande',
  },
  {
    key_ford: 'ResultCode',
    key_swe: 'Meddelande',
  },
  {
    key_ford: 'actionCode',
    key_swe: 'Åtgärdskod',
  },
  {
    key_ford: 'actionDateAndTime',
    key_swe: 'Åtgärdstidpunkt',
  },
  {
    key_ford: 'actionDescription',
    key_swe: 'Åtgärdsbeskrivning',
  },
  {
    key_ford: 'arrivalStation_code',
    key_swe: 'Till station kod',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'causeCode',
    key_swe: 'Orsakskod',
  },
  {
    key_ford: 'causeDescription',
    key_swe: 'Orsaksbeskrivning',
  },
  {
    key_ford: 'class',
    key_swe: 'Littera',
  },
  {
    key_ford: 'closingReportDateAndTime',
    key_swe: 'Avrapporteringstidpunkt',
  },
  {
    key_ford: 'closingReportDepot',
    key_swe: 'Avrapporterande verkstad',
  },
  {
    key_ford: 'confirmed',
    key_swe: 'Kvitterad',
  },
  {
    key_ford: 'consequenceCode',
    key_swe: 'Konsevenskod',
  },
  {
    key_ford: 'creationDateAndTime',
    key_swe: 'Inskrivet',
  },
  {
    key_ford: 'defectCode',
    key_swe: 'Skadekod',
  },
  {
    key_ford: 'defectDateAndTime',
    key_swe: 'Skadetidpunkt',
  },
  {
    key_ford: 'defectDescription',
    key_swe: 'Skadebeskrivning',
  },
  {
    key_ford: 'defectHeading',
    key_swe: 'Rubriktext',
  },
  {
    key_ford: 'defectNumber',
    key_swe: 'Skadelöpnummer',
  },
  {
    key_ford: 'defectRegistrationDepot',
    key_swe: 'Rapporterande plats',
  },
  {
    key_ford: 'delayMinutes',
    key_swe: 'Försening (min.)',
  },
  {
    key_ford: 'departureStationCode',
    key_swe: 'Från station kod',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'equipmentCheck',
    key_swe: 'Beredskapskontroll',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'ErrorReasonCode',
    key_swe: 'Felorsakskod',
  },
  {
    key_ford: 'ErrorReasonDescription',
    key_swe: 'Felorsaksbeskrivning',
  },
  {
    key_ford: 'externalReferenceId',
    key_swe: 'Externt unikt nummer',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'faultIndicationCode',
    key_swe: 'Felindikering',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'informationClassCode',
    key_swe: 'Information klass',
  },
  {
    key_ford: 'informationSource',
    key_swe: 'Uppgiftslämnare',
  },
  {
    key_ford: 'lastUpdatedDateAndTime',
    key_swe: 'Senast uppdaterad',
  },
  {
    key_ford: 'maintenanceReferenceId',
    key_swe: 'UH Ext. Skadenr.',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'outOfService',
    key_swe: 'Ur funktion',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'positionInTrain',
    key_swe: 'Ordningsnummer i tågsätt',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'positionInUnit',
    key_swe: 'Vagn',
  },
  {
    key_ford: 'positionInVehicle',
    key_swe: 'Position',
  },
  {
    key_ford: 'priority',
    key_swe: 'Prioritet',
  },
  {
    key_ford: 'reportedBy',
    key_swe: 'Rapportör',
  },
  {
    key_ford: 'safety',
    key_swe: 'Säkerhet',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'splitTable',
    key_swe: 'Underhållstabell',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'testedStationary',
    key_swe: 'Stillastående',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'testedTestDrive',
    key_swe: 'Provtur',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'testedWithoutRemark',
    key_swe: 'Utan anmärkning',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'totalPerformanceQuantity',
    key_swe: 'Total prestation',
  },
  {
    key_ford: 'totalPerformanceUnit',
    key_swe: 'Total prestations enhet',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'trafficDisturbance',
    key_swe: 'Trafikstörning',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'trainNumber',
    key_swe: 'Tåguppdrag',
  },
  {
    key_ford: 'warranty',
    key_swe: 'Garantiärende',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'workorderId',
    key_swe: 'Arbetsorder id',
    hidden: ['pdf-composition'],
  },
  {
    key_ford: 'vehicleNumber',
    key_swe: 'Fordonsnummer',
  },
  {
    key_ford: 'vehicleUniqueId',
    key_swe: 'Fiktivt fordonsnummer',
  },
]
