<template>
  <q-dialog v-model="fordModal.state.show" :persistent="false">
    <EventFordModal v-if="fordModal.state.show" />
  </q-dialog>
  <div :class="[$style.scrollContainer]" class="bg-grey-2" v-if="!loading">
    <div
      class="flex q-pa-md q-pt-md shadow-1"
      style="position: sticky; top: 0; z-index: 1; background: white"
    >
      <span class="text-body1 text-weight-bold q-pr-sm"> Avställda </span>
      <q-separator vertical />
      <div class="text-body2 text-grey-8 q-pl-sm">
        {{ selectedDate }} {{ selectedTime }}
      </div>
      <q-space />
      <span class="text-weight-bold q-pl-md lt-md">{{ withdrawnToday }}st</span>
    </div>
    <div class="q-pa-sm">
      <q-card
        v-for="row of filteredWithdrawals"
        :key="row.id"
        class="q-mb-sm"
        square
      >
        <q-card-section class="row q-py-xs">
          <div class="col-2 column">
            <span class="text-caption text-grey-8">Littera</span>
            <span class="text-weight-bold">{{ row.vehicleTypeName }}</span>
          </div>

          <div class="col-2 column">
            <span class="text-caption text-grey-8">Fordon</span>
            <span>{{ row.vehicleName }}</span>
          </div>

          <div class="col-2 column">
            <span class="text-caption text-grey-8">Depå</span>
            <span>{{ row.depot }}</span>
          </div>

          <div class="col-2 column">
            <span class="text-caption text-grey-8 text-no-wrap"
              >FORD skadenr.</span
            >
            <span
              style="
                display: flex;
                flex-direction: column;
                align-items: flex-start;
              "
            >
              <q-btn
                v-for="(ford, $i) of row._ford"
                :key="$i"
                outline
                dense
                size="12px"
                class="q-mb-xs"
                :disable="!can('deviationManagement.vehicleDefect.list')"
                @click.stop="onClickFordChip(ford)"
              >
                {{ ford }}
              </q-btn>
            </span>
          </div>

          <div class="col-12 column col-md-4" v-if="row.comment">
            <span class="text-caption text-grey-8">Kommentar</span>
            <span>{{ row.comment }}</span>
          </div>
        </q-card-section>

        <q-card-section class="flex q-py-xs">
          <q-chip square dense>
            <q-avatar color="grey-5" text-color="white">1</q-avatar>
            <span class="text-caption q-pl-sm">{{ row.reason1 }}</span>
          </q-chip>
          <q-chip square dense>
            <q-avatar color="grey-5" text-color="white">2</q-avatar>
            <span class="text-caption q-pl-sm">{{ row.reason2 }}</span>
          </q-chip>
          <q-chip square dense>
            <q-avatar color="grey-5" text-color="white">3</q-avatar>
            <span class="text-caption q-pl-sm">{{ row.reason3 }}</span>
          </q-chip>
        </q-card-section>

        <q-card-section class="row bg-grey-2 q-py-sm">
          <template v-if="!row.ready && row.prognosis">
            <div class="col-12 col-md-auto">
              <strong class="q-mr-xs text-caption text-weight-bold"
                >Prognos:</strong
              >
              <span class="text-caption">
                {{ format(new Date(row.prognosis), 'yyyy-MM-dd HH:mm') }}
              </span>
            </div>

            <q-separator vertical class="q-mx-md gt-sm" />
            <div class="col-12 col-md-auto items-center flex">
              <strong class="q-mr-xs text-caption text-weight-bold"
                >Prognossäkerthet:</strong
              >
              <q-badge
                class="q-mr-xs"
                :style="{
                  background: prognosisCertainties[row.prognosisCertainty],
                }"
              />
              <span class="text-caption">
                {{ row.prognosisCertainty }}
              </span>
            </div>
          </template>

          <template v-else-if="row.ready">
            <div class="col-12 col-md-auto">
              <strong class="q-mr-xs text-caption text-weight-bold"
                >Påställd:</strong
              >
              <span class="text-caption">
                {{ format(new Date(row.ready), 'yyyy-MM-dd HH:mm') }}
              </span>
            </div>
          </template>

          <q-space />

          <strong class="q-mr-xs text-caption text-weight-bold"
            >Avställd:</strong
          >
          <span class="text-caption">
            {{ format(new Date(row.withdrawn), 'yyyy-MM-dd HH:mm') }}
          </span>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script lang="ts">
import {
  Withdrawal,
  WithdrawalDashboardWithdrawal,
} from '@/types/withdrawal-dashboard-withdrawal'
import { computed, defineComponent, PropType, provide } from 'vue'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { Screen } from 'quasar'
import { useProfile } from '@/composable/useProfile'
import { useModal } from '@/composable/useModal'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import EventFordModal from '@/components/event/EventFordModal.vue'

const prognosisCertainties = {
  Osäker: 'red',
  Säker: 'green',
  'Ganska säker': 'rgb(240, 224, 26)',
}

export default defineComponent({
  name: 'VehicleAvailabilityWithdrawals',

  props: {
    selectedTime: String,
    selectedDate: String,
    loading: Boolean,
    withdrawnToday: Number,
    withdrawals: {
      type: Array as PropType<WithdrawalDashboardWithdrawal[]>,
      required: true,
    },
  },

  components: {
    EventFordModal,
  },

  setup(props) {
    const fordModal = useModal()
    provide('ford-modal', fordModal)
    const { can } = useProfile()
    const { vehicleTypeFilter } = useWithdrawalDashboard()

    const filteredWithdrawals = computed(() => {
      const withdrawals = props.withdrawals.reduce<
        ({ date: string; uuid: string } & Withdrawal)[]
      >((acc, withdrawal) => {
        acc = [
          ...acc,
          ...withdrawal.withdrawals.map((x) => ({
            ...x,
            uuid: uuidv4(),
            _ford:
              typeof x.ford === 'string'
                ? (x.ford as string).split(',').map((x) => x.trim())
                : [],
            date: withdrawal.date,
          })),
        ]

        return acc
      }, [])
      if (!vehicleTypeFilter.value.length) return withdrawals
      return withdrawals.filter((x) =>
        vehicleTypeFilter.value.some((littera) => littera === x.vehicleTypeName)
      )
    })

    const litteraTypes = computed(() => {
      const groups = props.withdrawals.reduce<{
        [group: string]: { [key: string]: string }
      }>((acc, withdrawal) => {
        withdrawal.withdrawals.forEach((x) => {
          if (!acc[x.vehicleTypeGroupName]) {
            acc[x.vehicleTypeGroupName] = {}
          }

          if (!acc[x.vehicleTypeGroupName][x.vehicleTypeName]) {
            acc[x.vehicleTypeGroupName][x.vehicleTypeName] = x.vehicleTypeName
          }
        })

        return acc
      }, {})

      return Object.keys(groups).map((group) => {
        return {
          label: group,
          children: Object.values(groups[group]).map((x) => ({ label: x })),
        }
      })
    })

    function onClickFordChip(defectNumber: number) {
      fordModal?.openModal({
        cb: async (setData) => {
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    return {
      filteredWithdrawals,
      litteraTypes,
      vehicleTypeFilter,
      format,
      Screen,
      can,
      onClickFordChip,
      fordModal,
      prognosisCertainties,
    }
  },
})
</script>

<style lang="scss" module>
.scrollContainer {
  height: calc(100vh - 118px);
  overflow: scroll;
  border-top: 1px solid rgba(0, 0, 0, 0.12);

  @media screen and (max-width: 1023px) {
    height: 100vh !important;
  }
}
</style>
