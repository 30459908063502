import { Ref, ref } from 'vue'
import {
  getSummary,
  GetSummaryParams,
} from '@/api/withdrawal-dashboard/getSummary'
import {
  getWithdrawal,
  GetWithdrawalParams,
} from '@/api/withdrawal-dashboard/getWithdrawal'
import { WithdrawalDashboardSummary } from '@/types/withdrawal-dashboard-summary'
import { WithdrawalDashboardWithdrawal } from '@/types/withdrawal-dashboard-withdrawal'

interface UseWithdrawalDashboard {
  data: Ref<Data>
  fetchSummary: (
    params: GetSummaryParams
  ) => Promise<WithdrawalDashboardSummary[]>
  fetchWithdrawal: (
    params: GetWithdrawalParams
  ) => Promise<WithdrawalDashboardWithdrawal[]>
  loading: Ref<Loading>
  vehicleTypeFilter: Ref<string[]>
}

interface Loading {
  fetchSummary: boolean
  fetchWithdrawal: boolean
}

interface Data {
  summary: WithdrawalDashboardSummary[]
  withdrawal: WithdrawalDashboardWithdrawal[]
}

const data = ref<Data>({ summary: [], withdrawal: [] })
const loading = ref<Loading>({
  fetchSummary: false,
  fetchWithdrawal: false,
})
const vehicleTypeFilter = ref<string[]>([])

export function useWithdrawalDashboard(): UseWithdrawalDashboard {
  async function fetchSummary(params: GetSummaryParams) {
    loading.value.fetchSummary = true
    return new Promise<WithdrawalDashboardSummary[]>((resolve, reject) => {
      getSummary(params)
        .then(
          ({
            data: withdrawalDashboardSummary,
          }: {
            data: WithdrawalDashboardSummary[]
          }) => {
            data.value.summary = withdrawalDashboardSummary
            resolve(withdrawalDashboardSummary)
          }
        )
        .catch(reject)
        .finally(() => {
          loading.value.fetchSummary = false
        })
    })
  }

  async function fetchWithdrawal(params: GetWithdrawalParams) {
    loading.value.fetchWithdrawal = true
    return new Promise<WithdrawalDashboardWithdrawal[]>((resolve, reject) => {
      getWithdrawal(params)
        .then(
          ({
            data: withdrawalDashboardWithdrawal,
          }: {
            data: WithdrawalDashboardWithdrawal[]
          }) => {
            data.value.withdrawal = withdrawalDashboardWithdrawal
            resolve(withdrawalDashboardWithdrawal)
          }
        )
        .catch(reject)
        .finally(() => {
          loading.value.fetchWithdrawal = false
        })
    })
  }

  return {
    data,
    fetchSummary,
    fetchWithdrawal,
    loading,
    vehicleTypeFilter,
  }
}
