import { render } from "./VehicleAvailabilityWithdrawals.vue?vue&type=template&id=5a83dbe4"
import script from "./VehicleAvailabilityWithdrawals.vue?vue&type=script&lang=ts"
export * from "./VehicleAvailabilityWithdrawals.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./VehicleAvailabilityWithdrawals.vue?vue&type=style&index=0&id=5a83dbe4&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QSeparator,QSpace,QCard,QCardSection,QBtn,QChip,QAvatar,QBadge});
