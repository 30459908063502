import { render } from "./VehicleAvailability.vue?vue&type=template&id=70d2b888"
import script from "./VehicleAvailability.vue?vue&type=script&lang=ts"
export * from "./VehicleAvailability.vue?vue&type=script&lang=ts"

const cssModules = script.__cssModules = {}
import style0 from "./VehicleAvailability.vue?vue&type=style&index=0&id=70d2b888&lang=scss&module=true"
cssModules["$style"] = style0
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QTable,QSelect,QBtnToggle,QLinearProgress,QTh,QBtn,QTr,QTd,QBadge,QInput});
