import { render } from "./EventFordModal.vue?vue&type=template&id=28f47282"
import script from "./EventFordModal.vue?vue&type=script&lang=ts"
export * from "./EventFordModal.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator,QScrollArea,QCardActions,QBtn});
