
import {
  Withdrawal,
  WithdrawalDashboardWithdrawal,
} from '@/types/withdrawal-dashboard-withdrawal'
import { computed, defineComponent, PropType, provide } from 'vue'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import Screen from 'quasar/src/plugins/Screen.js';
import { useProfile } from '@/composable/useProfile'
import { useModal } from '@/composable/useModal'
import { getVehicleDefect } from '@/api/vehicle-defect/getVehicleDefect'
import EventFordModal from '@/components/event/EventFordModal.vue'

const prognosisCertainties = {
  Osäker: 'red',
  Säker: 'green',
  'Ganska säker': 'rgb(240, 224, 26)',
}

export default defineComponent({
  name: 'VehicleAvailabilityWithdrawals',

  props: {
    selectedTime: String,
    selectedDate: String,
    loading: Boolean,
    withdrawnToday: Number,
    withdrawals: {
      type: Array as PropType<WithdrawalDashboardWithdrawal[]>,
      required: true,
    },
  },

  components: {
    EventFordModal,
  },

  setup(props) {
    const fordModal = useModal()
    provide('ford-modal', fordModal)
    const { can } = useProfile()
    const { vehicleTypeFilter } = useWithdrawalDashboard()

    const filteredWithdrawals = computed(() => {
      const withdrawals = props.withdrawals.reduce<
        ({ date: string; uuid: string } & Withdrawal)[]
      >((acc, withdrawal) => {
        acc = [
          ...acc,
          ...withdrawal.withdrawals.map((x) => ({
            ...x,
            uuid: uuidv4(),
            _ford:
              typeof x.ford === 'string'
                ? (x.ford as string).split(',').map((x) => x.trim())
                : [],
            date: withdrawal.date,
          })),
        ]

        return acc
      }, [])
      if (!vehicleTypeFilter.value.length) return withdrawals
      return withdrawals.filter((x) =>
        vehicleTypeFilter.value.some((littera) => littera === x.vehicleTypeName)
      )
    })

    const litteraTypes = computed(() => {
      const groups = props.withdrawals.reduce<{
        [group: string]: { [key: string]: string }
      }>((acc, withdrawal) => {
        withdrawal.withdrawals.forEach((x) => {
          if (!acc[x.vehicleTypeGroupName]) {
            acc[x.vehicleTypeGroupName] = {}
          }

          if (!acc[x.vehicleTypeGroupName][x.vehicleTypeName]) {
            acc[x.vehicleTypeGroupName][x.vehicleTypeName] = x.vehicleTypeName
          }
        })

        return acc
      }, {})

      return Object.keys(groups).map((group) => {
        return {
          label: group,
          children: Object.values(groups[group]).map((x) => ({ label: x })),
        }
      })
    })

    function onClickFordChip(defectNumber: number) {
      fordModal?.openModal({
        cb: async (setData) => {
          await getVehicleDefect(defectNumber)
            .then(({ data }) => {
              setData(data)
            })
            .catch((error) => {
              setData(error.response.data.message)
            })

          return Promise.resolve()
        },
      })
    }

    return {
      filteredWithdrawals,
      litteraTypes,
      vehicleTypeFilter,
      format,
      Screen,
      can,
      onClickFordChip,
      fordModal,
      prognosisCertainties,
    }
  },
})
