import { instance } from '@/services/avik-api-gateway'
import { CustomerAvailabilityStatus } from '@/types/customer-availability-status'
import { AxiosResponse } from 'axios'

export function updateCustomerAvailabilityStatus(
  body: CustomerAvailabilityStatus
): Promise<AxiosResponse<CustomerAvailabilityStatus>> {
  return instance.patch(`/customer-availability-status/${body.uuid}`, {
    color: body.color,
  })
}
