
import { computed, defineComponent, ref, watch } from 'vue'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { format, subDays, addDays, eachDayOfInterval } from 'date-fns'
import sv from 'date-fns/locale/sv'
import { WithdrawalDashboardSummary } from '@/types/withdrawal-dashboard-summary'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { DailyReportVehicleStatus } from '@/types/daily-report-vehicle-status'
import VehicleAvailabilityWithdrawals from '@/components/vehicle-availability/VehicleAvailabilityWithdrawals.vue'
import Screen from 'quasar/src/plugins/Screen.js';
import { useCustomerAvailabilityStatus } from '@/composable/useCustomerAvailabilityStatus'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'VehicleAvailability',

  components: {
    AppFieldDate,
    VehicleAvailabilityWithdrawals,
  },

  setup() {
    const { currentProject } = useProfile()
    const {
      loading: vehicleStatus,
      getAllVehicleStatus,
      updateVehicleStatus,
      data: vehicleStatusData,
    } = useDailyReport()
    const { fetchWithdrawal, fetchSummary, loading, data, vehicleTypeFilter } =
      useWithdrawalDashboard()

    const { data: customerAvailabilityStatus } =
      useCustomerAvailabilityStatus(true)

    const currentDate = ref<string>(format(new Date(), 'yyyy-MM-dd'))
    const selectedTime = ref('07:00')

    const from = computed(() =>
      format(subDays(new Date(currentDate.value), 3), 'yyyy-MM-dd')
    )
    const to = computed(() =>
      format(addDays(new Date(currentDate.value), 3), 'yyyy-MM-dd')
    )

    const period = computed(() => {
      return eachDayOfInterval({
        start: new Date(from.value),
        end: new Date(to.value),
      })
    })

    const vehicleTypeGroups = computed(() => {
      return vehicleStatusData.value.vehicleStatus.reduce<{
        [vehicleTypeGroup: string]: DailyReportVehicleStatus
      }>((acc, vehicleStatus) => {
        acc[vehicleStatus.vehicleTypeGroupName] = vehicleStatus
        return acc
      }, {})
    })

    watch(
      [currentDate, selectedTime],
      () => {
        const params = {
          from: from.value,
          to: to.value,
          time: selectedTime.value,
        }
        fetchSummary(params)
        fetchWithdrawal({
          from: currentDate.value,
          to: currentDate.value,
          time: selectedTime.value,
        })
        getAllVehicleStatus(currentDate.value)
      },
      {
        immediate: true,
      }
    )

    const allLoading = computed(() => {
      return (
        vehicleStatus.value.vehicleStatus.getAll ||
        loading.value.fetchSummary ||
        loading.value.fetchWithdrawal
      )
    })

    const columns = computed(() => {
      return [
        {
          align: 'left',
          name: 'group',
          label: '',
          field: 'group',
        },
        ...period.value
          .map((date: Date) => {
            const field = format(date, 'yyyy-MM-dd')
            return {
              align: 'center',
              name: field,
              label: format(date, 'dd LLL', { locale: sv }),
              field: field,
            }
          })
          .filter((x) => (Screen.gt.sm ? true : x.name === currentDate.value)),
      ]
    })

    const parents = computed(() => {
      const uniqueParents = data.value.summary.reduce<{
        [name: string]: {
          group: string
          vehiclesTypes: {
            [name: string]: {
              group: string
              [date: string]: WithdrawalDashboardSummary | string
            }
          }
          [date: string]:
            | { statusColor: string; total: number }
            | string
            | {
                [name: string]: {
                  group: string
                  [date: string]: WithdrawalDashboardSummary | string
                }
              }
        }
      }>((acc, vehicleGroup) => {
        const date = format(new Date(vehicleGroup.date), 'yyyy-MM-dd')
        if (!vehicleGroup.parent && !acc[vehicleGroup.name]) {
          const vehiclesTypes = data.value.summary
            .filter((x) => x.parent === vehicleGroup.name)
            .reduce<{
              [name: string]: {
                group: string
                [date: string]: WithdrawalDashboardSummary | string
              }
            }>((acc, vehicleType) => {
              const date = format(new Date(vehicleType.date), 'yyyy-MM-dd')
              if (!acc[vehicleType.name]) {
                acc[vehicleType.name] = {
                  group: vehicleType.name,
                  [date]: vehicleType,
                }
              } else {
                acc[vehicleType.name][date] = vehicleType
              }
              return acc
            }, {})
          acc[vehicleGroup.name] = {
            group: vehicleGroup.name,
            vehiclesTypes,
            [date]: {
              statusColor: vehicleGroup.statusColor,
              total: vehicleGroup.available,
            },
          }
        } else if (!vehicleGroup.parent && acc[vehicleGroup.name]) {
          acc[vehicleGroup.name][date] = {
            statusColor: vehicleGroup.statusColor,
            total: vehicleGroup.available,
          }
        }

        return acc
      }, {})

      return Object.values(uniqueParents)
    })

    const withdrawnRow = computed(() => {
      return data.value.summary.reduce<{ [date: string]: number }>(
        (acc, vehicleType) => {
          if (!vehicleType.parent) return acc
          const date = format(new Date(vehicleType.date), 'yyyy-MM-dd')
          if (!acc[date]) {
            acc[date] = vehicleType.withdrawn
          } else {
            acc[date] += vehicleType.withdrawn
          }

          return acc
        },
        {}
      )
    })

    const withdrawnToday = computed(() => {
      return withdrawnRow.value[currentDate.value]
    })

    const expanded = ref<string[]>([])

    function onExpand() {
      if (expanded.value.length) {
        expanded.value = []
      } else {
        expanded.value = parents.value.map((x) => x.group)
      }
    }

    function isDateLabelSelected(dateLabel: string) {
      return currentDate.value === dateLabel
    }

    function selectDate(date: string) {
      currentDate.value = date
    }

    function onSaveComment(v: string, item: DailyReportVehicleStatus) {
      const body = {
        uuid: item.uuid,
        date: item.date,
        vehicleTypeGroupUuid: item.vehicleTypeGroupUuid,
        required: item.required,
        available: item.available,
        comment: v,
      }
      updateVehicleStatus(body)
    }

    function dayFormat(date: string) {
      return format(new Date(date), 'eeee', {
        locale: sv,
      })
    }

    function toggleVehicleType(vehicleType: string) {
      if (!vehicleTypeFilter.value.includes(vehicleType)) {
        vehicleTypeFilter.value.push(vehicleType)
      } else {
        vehicleTypeFilter.value = vehicleTypeFilter.value.filter(
          (x) => x !== vehicleType
        )
      }
    }

    function toggleVehicleTypeGroup(vehicleTypes: string[]) {
      const shouldAdd = !vehicleTypes.every((x) =>
        vehicleTypeFilter.value.includes(x)
      )

      if (shouldAdd) {
        vehicleTypeFilter.value = [
          ...new Set([...vehicleTypeFilter.value, ...vehicleTypes]),
        ]
      } else {
        vehicleTypeFilter.value = vehicleTypeFilter.value.filter(
          (x) => !vehicleTypes.includes(x)
        )
      }
    }

    function isVehicleTypeGroupSelected(vehicleTypes: string[]) {
      return vehicleTypes.every((x) => vehicleTypeFilter.value.includes(x))
    }

    function isSomeVehicleTypeGroupSelected(vehicleTypes: string[]) {
      return vehicleTypes.some((x) => vehicleTypeFilter.value.includes(x))
    }

    const isKrosa = computed(() => {
      return currentProject.value?.name === 'krosatag'
    })

    const statusColors = computed(() => {
      return ['Normalt', 'Acceptabelt', 'Ansträngt', 'Mycket ansträngt']
        .filter((x) => {
          if (isKrosa.value && x === 'Mycket ansträngt') {
            return false
          }
          return true
        })
        .map((status) =>
          customerAvailabilityStatus.value.find((x) => x.status === status)
        )
    })

    const fnHourMarkers = (val: number) => `${`${val}`.padStart(2, '0')}:00`
    const hoursOptions = Array(24)
      .fill(null)
      .map((_, i) => fnHourMarkers(i))

    return {
      isKrosa,
      allLoading,
      data,
      columns,
      parents,
      expanded,
      onExpand,
      selectedTime,
      isDateLabelSelected,
      selectDate,
      currentDate,
      withdrawnRow,
      vehicleTypeGroups,
      onSaveComment,
      dayFormat,
      vehicleTypeFilter,
      toggleVehicleType,
      toggleVehicleTypeGroup,
      isVehicleTypeGroupSelected,
      Screen,
      withdrawnToday,
      isSomeVehicleTypeGroupSelected,
      statusColors,
      fnHourMarkers,
      hoursOptions,
    }
  },
})
