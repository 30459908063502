<template>
  <q-page>
    <div class="row">
      <div class="col-12 col-md-6">
        <q-table
          table-style="border-top: 1px solid rgba(0,0,0,0.12)"
          title="Fordonstillgänglighet"
          :rows="parents"
          :loading="allLoading"
          :columns="columns"
          :rows-per-page-options="[0]"
          :class="[$style.stickyHeader]"
          hide-pagination
          flat
          row-key="group"
          v-model:expanded="expanded"
          dense
        >
          <template v-slot:top>
            <div :class="$style.topRow">
              <div style="flex: 1">
                <div class="row q-col-gutter-md">
                  <div class="col-auto">
                    <AppFieldDate label="Datum" dense v-model="currentDate" />
                  </div>
                  <div class="col-auto">
                    <q-select
                      dense
                      square
                      outlined
                      label="Tid"
                      v-model="selectedTime"
                      :options="hoursOptions"
                    />
                  </div>
                </div>
              </div>
              <!-- <q-btn-toggle
                size="sm"
                stretch
                class="q-ml-md q-mb-xs lt-md"
                v-model="selectedTime"
                toggle-color="secondary"
                :options="[
                  { label: '07', value: '07:00' },
                  { label: '16', value: '16:00' },
                ]"
              /> -->
              <div :class="$style.progress">
                <q-linear-progress size="2px" indeterminate v-if="allLoading" />
              </div>
            </div>
          </template>
          <template v-slot:header-cell="props">
            <q-th :props="props" style="padding: 0">
              <template v-if="props.col.name === 'group'">
                <!-- <q-btn-toggle
                  size="sm"
                  stretch
                  class="q-ml-md q-mb-xs gt-sm"
                  v-model="selectedTime"
                  toggle-color="secondary"
                  :options="[
                    { label: '07', value: '07:00' },
                    { label: '16', value: '16:00' },
                  ]"
                /> -->

                <div class="flex q-ml-md q-mb-xs lt-md" style="height: 100%">
                  <q-btn stretch size="sm" @click="onExpand" outline>{{
                    expanded.length ? 'Minimera' : 'Expandera'
                  }}</q-btn>
                </div>
              </template>
              <template v-else>
                <q-btn
                  stretch
                  @click="selectDate(props.col.name)"
                  padding="sm"
                  flat
                  no-caps
                  class="full-width"
                  :color="
                    isDateLabelSelected(props.col.name) ? 'secondary' : null
                  "
                >
                  <div class="items-center justify-center lt-md">
                    <span>{{ props.col.label }} </span>
                    <span class="text-capitalize q-pl-md">{{
                      dayFormat(props.col.name)
                    }}</span>
                  </div>

                  <div class="column items-center justify-center gt-sm">
                    <span>{{ props.col.label }} </span>
                    <span class="text-capitalize">{{
                      dayFormat(props.col.name)
                    }}</span>
                  </div>
                </q-btn>
              </template>
            </q-th>
          </template>

          <template v-slot:top-row>
            <q-tr v-if="!allLoading" key="top-row" class="gt-sm">
              <td>
                <div class="flex" :style="{ height: isKrosa ? '' : '100%' }">
                  <q-btn stretch size="sm" @click="onExpand" outline>{{
                    expanded.length ? 'Minimera' : 'Expandera'
                  }}</q-btn>
                </div>
              </td>
              <q-td colspan="100%">
                <div class="flex justify-between items-center">
                  <div class="flex items-center">
                    <span class="text-body2 q-mr-md" v-if="!isKrosa"
                      >Fordonsläge:</span
                    >

                    <template v-if="isKrosa">
                      <div
                        v-for="status in statusColors"
                        :key="status.status"
                        class="flex"
                        style="flex-direction: column"
                      >
                        <div class="flex items-center">
                          <q-badge
                            rounded
                            :style="{ background: status.color }"
                          />
                          <span
                            class="text-caption q-ml-sm text-grey-8 q-pr-sm"
                            style="font-weight: bold"
                            >{{ status.status }}</span
                          >
                        </div>
                        <div
                          style="
                            padding-left: 20px;
                            padding-right: 20px;
                            white-space: pre-wrap;
                            padding-bottom: 8px;
                            font-size: 11px;
                            color: rgba(0, 0, 0, 0.8);
                          "
                        >
                          <template v-if="status.status === 'Normalt'">
                            Vi har fordon för att klara av alla avgångar samt
                            fordon för tillsyn och fordonsreserver. Läget är
                            gott och vi förväntar oss inga inställda avgångar
                            pga fordonsbrist. Vid fordonskador så förväntas
                            fordon kunna bytas ut snabbt och att inställda
                            avgångar hålls på en låg nivå.
                          </template>
                          <template v-if="status.status === 'Acceptabelt'">
                            Vi har fordon för att klara av alla avgångar samt
                            fordon för tillsyn. Fordonsreserver finns i lägre
                            eller inget antal.
                          </template>
                          <template v-if="status.status === 'Ansträngt'">
                            Fordon finns inte för att klara av ordinarie trafik.
                            Inga fordonsreserver finns därutav heller. Det
                            kommer att bli inställda avgångar under dagen pga
                            fordonsbrist. Detta kan få påverkan på underhållet.
                          </template>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div
                        v-for="status in statusColors"
                        :key="status.status"
                        class="flex items-center"
                      >
                        <q-badge
                          rounded
                          :style="{ background: status.color }"
                        />
                        <span
                          class="text-caption q-ml-sm text-grey-8 q-pr-sm"
                          >{{ status.status }}</span
                        >
                      </div>
                    </template>
                  </div>
                </div>
              </q-td>
            </q-tr>
          </template>

          <template v-slot:bottom-row="props">
            <q-tr v-if="!allLoading" key="bottom-row" class="gt-sm">
              <q-td
                v-for="(col, $i) in props.cols"
                :key="'bottom-' + $i"
                :class="[$style.border, $style.borderBottom]"
              >
                <template v-if="col.name === 'group'">
                  <span class="text-weight-bold">Avställda: </span>
                </template>
                <template v-else>
                  <div
                    class="flex items-center justify-center"
                    :class="[
                      !Screen.lt.md &&
                        isDateLabelSelected(col.name) &&
                        $style.isSelected,
                    ]"
                  >
                    <div
                      style="
                        white-space: nowrap;
                        min-width: 70px;
                        padding-left: 8px;
                      "
                      class="flex items-center justify-start"
                    >
                      <q-badge color="white" />
                      <span class="text-body1 text-weight-bold q-ml-sm">{{
                        withdrawnRow[col.name]
                      }}</span>
                    </div>
                  </div>
                </template>
              </q-td>
            </q-tr>
          </template>

          <template v-slot:body="props">
            <q-tr :props="props" v-if="!allLoading">
              <q-td
                v-for="col in props.cols"
                :key="col.name"
                :props="props"
                :class="$style.border"
              >
                <template v-if="col.name === 'group'">
                  <q-btn
                    size="sm"
                    round
                    flat
                    dense
                    @click="props.expand = !props.expand"
                    :icon="props.expand ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                    class="q-mr-md"
                  />

                  <q-btn
                    dense
                    stretch
                    @click="
                      toggleVehicleTypeGroup(
                        Object.values(props.row.vehiclesTypes).map(
                          (x) => x.group
                        )
                      )
                    "
                    size="11px"
                    :outline="
                      !isVehicleTypeGroupSelected(
                        Object.values(props.row.vehiclesTypes).map(
                          (x) => x.group
                        )
                      ) &&
                      isSomeVehicleTypeGroupSelected(
                        Object.values(props.row.vehiclesTypes).map(
                          (x) => x.group
                        )
                      )
                    "
                    :color="
                      isSomeVehicleTypeGroupSelected(
                        Object.values(props.row.vehiclesTypes).map(
                          (x) => x.group
                        )
                      )
                        ? 'secondary'
                        : ''
                    "
                    :flat="
                      !isSomeVehicleTypeGroupSelected(
                        Object.values(props.row.vehiclesTypes).map(
                          (x) => x.group
                        )
                      )
                    "
                    >{{ col.value }}</q-btn
                  >
                </template>
                <template v-else>
                  <div
                    class="flex items-center justify-center"
                    :class="[
                      !Screen.lt.md &&
                        isDateLabelSelected(col.name) &&
                        $style.isSelectedExpanded,
                    ]"
                  >
                    <div
                      style="
                        white-space: nowrap;
                        min-width: 70px;
                        padding-left: 8px;
                      "
                      class="flex items-center justify-start"
                    >
                      <q-badge :style="{ background: col.value.statusColor }" />
                      <span class="text-body1 q-ml-sm">{{
                        col.value.total
                      }}</span>
                    </div>
                  </div>
                </template>
              </q-td>
            </q-tr>
            <template v-if="props.expand && !allLoading">
              <q-tr
                :props="props"
                v-for="(vehicleType, $i) in props.row.vehiclesTypes"
                :key="$i"
              >
                <q-td v-for="col in props.cols" :key="col.name" :props="props">
                  <template v-if="col.name === 'group'">
                    <div style="margin-left: 41px">
                      <q-btn
                        @click="toggleVehicleType(vehicleType.group)"
                        dense
                        stretch
                        size="11px"
                        :color="
                          vehicleTypeFilter.includes(vehicleType.group)
                            ? 'secondary'
                            : ''
                        "
                        :flat="!vehicleTypeFilter.includes(vehicleType.group)"
                        >{{ vehicleType.group }}</q-btn
                      >
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="flex items-center justify-center"
                      :class="[
                        !Screen.lt.md &&
                          isDateLabelSelected(col.name) &&
                          $style.isSelected,
                      ]"
                    >
                      <div
                        style="
                          white-space: nowrap;
                          min-width: 70px;
                          padding-left: 8px;
                        "
                        class="flex items-center justify-start"
                      >
                        <q-badge
                          :style="{
                            background: vehicleType[col.field].statusColor,
                          }"
                        />
                        <span class="text-body1 q-ml-sm">
                          {{ vehicleType[col.field].available }}</span
                        >
                      </div>
                    </div>
                  </template>
                </q-td>
              </q-tr>
            </template>

            <q-tr :props="props">
              <q-td :class="$style.borderTop"> </q-td>
              <q-td colspan="100%" :class="$style.borderTop">
                <q-input
                  v-if="vehicleTypeGroups[props.row.group]"
                  style="flex: 1"
                  dense
                  placeholder="Kommentar..."
                  type="textarea"
                  autogrow
                  readonly
                  square
                  borderless
                  debounce="500"
                  :model-value="vehicleTypeGroups[props.row.group].comment"
                  @update:model-value="
                    (v) => onSaveComment(v, vehicleTypeGroups[props.row.group])
                  "
                />
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
      <div
        class="col-12 col-md-6"
        style="border-left: 1px solid rgba(0, 0, 0, 0.12)"
      >
        <VehicleAvailabilityWithdrawals
          :withdrawals="data.withdrawal"
          :loading="allLoading"
          :selected-date="currentDate"
          :selected-time="selectedTime"
          :withdrawn-today="withdrawnToday"
        />
      </div>
    </div>
  </q-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useWithdrawalDashboard } from '@/composable/useWithdrawalDashboard'
import { format, subDays, addDays, eachDayOfInterval } from 'date-fns'
import sv from 'date-fns/locale/sv'
import { WithdrawalDashboardSummary } from '@/types/withdrawal-dashboard-summary'
import AppFieldDate from '@/components/AppFieldDate.vue'
import { useDailyReport } from '@/composable/useDailyReport'
import { DailyReportVehicleStatus } from '@/types/daily-report-vehicle-status'
import VehicleAvailabilityWithdrawals from '@/components/vehicle-availability/VehicleAvailabilityWithdrawals.vue'
import { Screen } from 'quasar'
import { useCustomerAvailabilityStatus } from '@/composable/useCustomerAvailabilityStatus'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'VehicleAvailability',

  components: {
    AppFieldDate,
    VehicleAvailabilityWithdrawals,
  },

  setup() {
    const { currentProject } = useProfile()
    const {
      loading: vehicleStatus,
      getAllVehicleStatus,
      updateVehicleStatus,
      data: vehicleStatusData,
    } = useDailyReport()
    const { fetchWithdrawal, fetchSummary, loading, data, vehicleTypeFilter } =
      useWithdrawalDashboard()

    const { data: customerAvailabilityStatus } =
      useCustomerAvailabilityStatus(true)

    const currentDate = ref<string>(format(new Date(), 'yyyy-MM-dd'))
    const selectedTime = ref('07:00')

    const from = computed(() =>
      format(subDays(new Date(currentDate.value), 3), 'yyyy-MM-dd')
    )
    const to = computed(() =>
      format(addDays(new Date(currentDate.value), 3), 'yyyy-MM-dd')
    )

    const period = computed(() => {
      return eachDayOfInterval({
        start: new Date(from.value),
        end: new Date(to.value),
      })
    })

    const vehicleTypeGroups = computed(() => {
      return vehicleStatusData.value.vehicleStatus.reduce<{
        [vehicleTypeGroup: string]: DailyReportVehicleStatus
      }>((acc, vehicleStatus) => {
        acc[vehicleStatus.vehicleTypeGroupName] = vehicleStatus
        return acc
      }, {})
    })

    watch(
      [currentDate, selectedTime],
      () => {
        const params = {
          from: from.value,
          to: to.value,
          time: selectedTime.value,
        }
        fetchSummary(params)
        fetchWithdrawal({
          from: currentDate.value,
          to: currentDate.value,
          time: selectedTime.value,
        })
        getAllVehicleStatus(currentDate.value)
      },
      {
        immediate: true,
      }
    )

    const allLoading = computed(() => {
      return (
        vehicleStatus.value.vehicleStatus.getAll ||
        loading.value.fetchSummary ||
        loading.value.fetchWithdrawal
      )
    })

    const columns = computed(() => {
      return [
        {
          align: 'left',
          name: 'group',
          label: '',
          field: 'group',
        },
        ...period.value
          .map((date: Date) => {
            const field = format(date, 'yyyy-MM-dd')
            return {
              align: 'center',
              name: field,
              label: format(date, 'dd LLL', { locale: sv }),
              field: field,
            }
          })
          .filter((x) => (Screen.gt.sm ? true : x.name === currentDate.value)),
      ]
    })

    const parents = computed(() => {
      const uniqueParents = data.value.summary.reduce<{
        [name: string]: {
          group: string
          vehiclesTypes: {
            [name: string]: {
              group: string
              [date: string]: WithdrawalDashboardSummary | string
            }
          }
          [date: string]:
            | { statusColor: string; total: number }
            | string
            | {
                [name: string]: {
                  group: string
                  [date: string]: WithdrawalDashboardSummary | string
                }
              }
        }
      }>((acc, vehicleGroup) => {
        const date = format(new Date(vehicleGroup.date), 'yyyy-MM-dd')
        if (!vehicleGroup.parent && !acc[vehicleGroup.name]) {
          const vehiclesTypes = data.value.summary
            .filter((x) => x.parent === vehicleGroup.name)
            .reduce<{
              [name: string]: {
                group: string
                [date: string]: WithdrawalDashboardSummary | string
              }
            }>((acc, vehicleType) => {
              const date = format(new Date(vehicleType.date), 'yyyy-MM-dd')
              if (!acc[vehicleType.name]) {
                acc[vehicleType.name] = {
                  group: vehicleType.name,
                  [date]: vehicleType,
                }
              } else {
                acc[vehicleType.name][date] = vehicleType
              }
              return acc
            }, {})
          acc[vehicleGroup.name] = {
            group: vehicleGroup.name,
            vehiclesTypes,
            [date]: {
              statusColor: vehicleGroup.statusColor,
              total: vehicleGroup.available,
            },
          }
        } else if (!vehicleGroup.parent && acc[vehicleGroup.name]) {
          acc[vehicleGroup.name][date] = {
            statusColor: vehicleGroup.statusColor,
            total: vehicleGroup.available,
          }
        }

        return acc
      }, {})

      return Object.values(uniqueParents)
    })

    const withdrawnRow = computed(() => {
      return data.value.summary.reduce<{ [date: string]: number }>(
        (acc, vehicleType) => {
          if (!vehicleType.parent) return acc
          const date = format(new Date(vehicleType.date), 'yyyy-MM-dd')
          if (!acc[date]) {
            acc[date] = vehicleType.withdrawn
          } else {
            acc[date] += vehicleType.withdrawn
          }

          return acc
        },
        {}
      )
    })

    const withdrawnToday = computed(() => {
      return withdrawnRow.value[currentDate.value]
    })

    const expanded = ref<string[]>([])

    function onExpand() {
      if (expanded.value.length) {
        expanded.value = []
      } else {
        expanded.value = parents.value.map((x) => x.group)
      }
    }

    function isDateLabelSelected(dateLabel: string) {
      return currentDate.value === dateLabel
    }

    function selectDate(date: string) {
      currentDate.value = date
    }

    function onSaveComment(v: string, item: DailyReportVehicleStatus) {
      const body = {
        uuid: item.uuid,
        date: item.date,
        vehicleTypeGroupUuid: item.vehicleTypeGroupUuid,
        required: item.required,
        available: item.available,
        comment: v,
      }
      updateVehicleStatus(body)
    }

    function dayFormat(date: string) {
      return format(new Date(date), 'eeee', {
        locale: sv,
      })
    }

    function toggleVehicleType(vehicleType: string) {
      if (!vehicleTypeFilter.value.includes(vehicleType)) {
        vehicleTypeFilter.value.push(vehicleType)
      } else {
        vehicleTypeFilter.value = vehicleTypeFilter.value.filter(
          (x) => x !== vehicleType
        )
      }
    }

    function toggleVehicleTypeGroup(vehicleTypes: string[]) {
      const shouldAdd = !vehicleTypes.every((x) =>
        vehicleTypeFilter.value.includes(x)
      )

      if (shouldAdd) {
        vehicleTypeFilter.value = [
          ...new Set([...vehicleTypeFilter.value, ...vehicleTypes]),
        ]
      } else {
        vehicleTypeFilter.value = vehicleTypeFilter.value.filter(
          (x) => !vehicleTypes.includes(x)
        )
      }
    }

    function isVehicleTypeGroupSelected(vehicleTypes: string[]) {
      return vehicleTypes.every((x) => vehicleTypeFilter.value.includes(x))
    }

    function isSomeVehicleTypeGroupSelected(vehicleTypes: string[]) {
      return vehicleTypes.some((x) => vehicleTypeFilter.value.includes(x))
    }

    const isKrosa = computed(() => {
      return currentProject.value?.name === 'krosatag'
    })

    const statusColors = computed(() => {
      return ['Normalt', 'Acceptabelt', 'Ansträngt', 'Mycket ansträngt']
        .filter((x) => {
          if (isKrosa.value && x === 'Mycket ansträngt') {
            return false
          }
          return true
        })
        .map((status) =>
          customerAvailabilityStatus.value.find((x) => x.status === status)
        )
    })

    const fnHourMarkers = (val: number) => `${`${val}`.padStart(2, '0')}:00`
    const hoursOptions = Array(24)
      .fill(null)
      .map((_, i) => fnHourMarkers(i))

    return {
      isKrosa,
      allLoading,
      data,
      columns,
      parents,
      expanded,
      onExpand,
      selectedTime,
      isDateLabelSelected,
      selectDate,
      currentDate,
      withdrawnRow,
      vehicleTypeGroups,
      onSaveComment,
      dayFormat,
      vehicleTypeFilter,
      toggleVehicleType,
      toggleVehicleTypeGroup,
      isVehicleTypeGroupSelected,
      Screen,
      withdrawnToday,
      isSomeVehicleTypeGroupSelected,
      statusColors,
      fnHourMarkers,
      hoursOptions,
    }
  },
})
</script>

<style lang="scss" module>
$tableHeight: calc(100vh - 118px);

.stickyHeader {
  /* height or max-height is important */
  height: $tableHeight !important;

  @media screen and (max-width: 1023px) {
    height: auto !important;
  }

  td {
    border-color: white;
  }

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    /* bg color is important for th; just specify one */
    background-color: white;
  }
  thead tr th {
    position: sticky;
    z-index: 2;
  }
  thead tr:first-child th {
    top: 0;
    z-index: 4;
  }

  thead tr:first-child th:first-child {
    z-index: 5 !important;
  }
  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
    z-index: 3;
  }

  .q-table th {
    white-space: nowrap !important;
  }

  tr:first-child th:first-child {
    /* highest z-index */
    z-index: 3;
  }

  td:first-child {
    z-index: 1;
  }

  td:first-child,
  th:first-child {
    max-width: 150px;
    min-width: 150px;
    width: 150px;
    position: sticky;
    left: 0;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.border {
  border-top-width: 1px !important;
  border-top-color: rgba(0, 0, 0, 0.12) !important;
  border-bottom-width: 0px !important;
}

.borderBottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.borderTop {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.isSelected {
  position: relative;

  &:after {
    content: '';
    background: rgba(#007694, 0.1);
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 33px;
    width: 100%;
    border-left: 1px solid rgba(#007694, 0.4);
    border-right: 1px solid rgba(#007694, 0.4);
  }
}

.isSelectedExpanded {
  position: relative;

  &:after {
    content: '';
    background: rgba(#007694, 0.1);
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 33px;
    width: 100%;
    border-left: 1px solid rgba(#007694, 0.4);
    border-right: 1px solid rgba(#007694, 0.4);
  }
}

.topRow {
  position: relative;
  width: 100%;
  display: flex;
}

.progress {
  position: absolute;
  bottom: -6px;
  left: -16px;
  right: 0;
  width: calc(100% + 32px);
}
</style>
