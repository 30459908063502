
import { UseModal } from '@/composable/useModal'
import { defineComponent, inject } from 'vue'
import vehicleReportHeaders from '@/common/vehicleReportHeaders'

export default defineComponent({
  name: 'EventFordModal',

  props: {
    eventId: Number,
  },

  setup() {
    const fordModal = inject<UseModal>('ford-modal')

    const vehicleDefect = fordModal?.state.data

    return { fordModal, vehicleDefect, vehicleReportHeaders }
  },
})
