import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { Log } from '@/types/log'

export function getVehicleDefect(
  defectNumber: number
): Promise<AxiosResponse<Log[]>> {
  return instance.get(`/vehicle-defect/`, {
    params: {
      defectNumber,
    },
  })
}
